<template>
  <div class="product-wrap">
    <CosmosWorks color="black" style="left: 80px;position: absolute;"></CosmosWorks>
    <div class="swiper scene-swiper">

      <div class="swiper-wrapper scene-swiper-wrapper">
        <div class="swiper-slide" v-for="item in productsConfig" :key="item.key">
          <img :src="item.main_img" width="100%" :alt="item.title">
        </div>
      </div>
      <transition name="fadeIn">
        <div class="product-info" v-if="infoShow">
          <h5>{{ currentProduct.name }} | {{ currentProduct.title }}</h5>
          <p>{{ currentProduct.description }}</p>
          <a class="product-link" href="/">了解详情</a>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

import Swiper from "swiper";
import CosmosWorks from "./Common/CosmosWorks.vue";
import productsConfig from "../config/products";

export default {
  data() {
    return {
      cards: ["01", "02", "03"],
      swiper: null,
      textShow: true,
      productsConfig,
      index: 0,
      infoShow: true
    };
  },
  components: {
    CosmosWorks,
  },
  computed: {
    currentProduct() {
      return this.productsConfig[this.index]
    }
  },
  methods: {

  },
  async mounted() {
    const that = this;
    this.swiper = new Swiper(".scene-swiper", {
      loop: true,
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 3,
      spaceBetween: -140,
      autoplay: 5000,
      on: {
        transitionStart: function () {
          that.infoShow = false;
          // console.log('start---', swiper.realIndex)
        },
        transitionEnd: function (swiper) {
          that.index = swiper.realIndex
          that.infoShow = true;
        }
      }
    });
    // console.log(this.swiper)
  },
};
</script>
<style lang="less" scoped>
.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.swiper {
  position: absolute;
  width: 2234px;
  height: 858px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  // margin-left: -8%;
}

.swiper-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  top: -40px;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1) !important;
  opacity: 1 !important;
}

.swiper-slide {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.6);
  opacity: 0.5;

  img {
    object-fit: cover;
  }

  p {
    position: absolute;
    width: 100%;
    text-align: center;
    color: red;
    height: 40px;
    left: 0;
    bottom: 0;
    font-size: 36px;
    line-height: 40px;
  }
}

.product-info {
  position: absolute;
  color: #000;
  width: 100%;
  text-align: center;
  bottom: 0;
  z-index: 100;

  p {
    font-size: 24px;
    color: #666666;
    line-height: 34px;
    margin: 0 0 30px 0;
  }

  h5 {
    font-size: 36px;
    line-height: 52px;
    margin: 0 0 15px 0;
  }

  .product-link {
    width: 136px;
    height: 50px;
    line-height: 50px;
    color: #00953B;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #00953B;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    margin: 0 auto;
  }
}

.product-wrap {
  // padding: 0 80px
  padding: 80px 0 120px 0;
  width: 100%;
  position: relative;
}</style>