<template>
  <div :style="`width:${width}px;height:${height}px;border-color:${color};color:${color}`" class="cms-btn" @click="click">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
      default: 80,
    },
    color: {
      type: String,
      default: '#00953B',
    },
    hover:{
      type: String,
      default: '#ffffff',
    }
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="less" scoped>
.cms-btn{
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  border-width: 1px;
  border-radius: 14px;
  border-style: solid;
  cursor: pointer;
}
</style>