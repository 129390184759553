export default [
  {
    key:'model_a',
    name:'Model A',
    title: '砾石电助力公路车',
    description: '全能伙伴，随心所欲拥抱自然',
    main_img: 'https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/model_a.png',
    price: '13,500',
    discount_price: null,
    banner_img: null,
  },
  {
    key:'model_e',
    name:'Model E',
    title: '电助力公路车',
    description: '人车合一，无视续航里程焦虑',
    main_img: 'https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/model_e.png',
    price: '22,600',
    discount_price: null,
    banner_img: null,
  },
  {
    key:'model_r',
    name:'Model R',
    title: '电助力山地车',
    description: '澎湃动力，解锁极致骑行乐趣',
    main_img: 'https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/model_r.png',
    price: '16,800',
    discount_price: null,
    banner_img: null,
  }
]