<template>
  <div class="n-wrap">
    <Menu ref="menu"></Menu>
    <video-wrap ref="videoWrap" @videoLoaded="videoLoaded"></video-wrap>
    <Product></Product>
    <ExprienceCenter></ExprienceCenter>
    <Brand></Brand>
    <BlockList></BlockList>
    <Foot></Foot>
    <!-- <Loading v-if="loading" ref="loadingComp"></Loading> -->
  </div>
</template>
<script>
import Menu from "../components/Menu.vue";
import ExprienceCenter from '../components/ExprienceCenter.vue'
import Brand from '../components/Brand.vue'
import BlockList from "../components/BlockList.vue";
import VideoWrap from "../components/VideoWrap.vue";
import Foot from "../components/Foot.vue";
import Product from "../components/Product.vue";
// import Loading from "../components/Common/Loading.vue";
export default {
  components: {
    Menu,
    VideoWrap,
    Foot,
    ExprienceCenter,
    Brand,
    BlockList,
    Product
  },
  data() {
    return {
      wrap: null,
      scrollTop: 0,
      cancelEvent: true,
      isScrolling: false,
      videoReady: false,
      animReady: true,
      loading: true,
    };
  },
  methods: {
    sceneEnd() {
      this.cancelEvent = false;
    },
    cacuLoadingStatus() {
      const loading = !(this.videoReady && this.animReady);
      if (!loading) {
        this.$refs.videoWrap.videPlay();
        this.$refs.loadingComp && this.$refs.loadingComp.loadEnd();
        setTimeout(() => {
          this.loading = false;
        }, 150);
      } else {
        this.loading = true;
      }
    },
    videoLoaded() {
      this.videoReady = true;
      this.cacuLoadingStatus();
    },
    animStartLoad() {
      this.animReady = false;
      this.cacuLoadingStatus();
    },
    animLoaded() {
      this.animReady = true;
      this.cacuLoadingStatus();
    },
    handleWheel() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop, window.innerHeight)
      if (scrollTop >= window.innerHeight) {
        this.$refs.menu.changeBg(true)
      } else {
        this.$refs.menu.changeBg(false)
      }

    },
  },
  mounted() {
    const target = this.$route.query.target;
    if (target) {
      this.$refs.menu.scrollToTarget(target);
    } else {
      history.scrollRestoration = "manual";
    }
    window.addEventListener("scroll", this.handleWheel, true);
  },
  beforeCreate() {
    // const ischeckCode = window.localStorage.getItem("checkCode");
    // if (!ischeckCode) {
    //   return this.$router.push("/verify");
    // }
    // if (this.$isMobile) {
    //   return this.$router.push("/m");
    // }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleWheel, true);
  },
};
</script>
<style lang="less">
.n-wrap {
  /* height: 200vh; */
  width: 100%;
  overflow: hidden;
}

.star-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.index-foot {
  padding-bottom: 156px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .foot-bg {
    // height: 1712px;
    // background: url(../assets/foot_bg.png) no-repeat bottom center;
    // background-size: 100% auto;
    position: relative;

    // &::after{
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 1053px;
    //   background-image: linear-gradient(rgba(17, 173, 231, 0) 30.79%,#000000 87.02%);
    //   left: 0;
    //   bottom: 0;
    // }
    .f-cloud {
      position: absolute;
      // background: url(../assets/cloud_1.png) no-repeat top center;
      background-size: 100% 100%;
      z-index: 1;
    }

    .cloud-1 {
      width: 495px;
      height: 282px;
      top: 643px;
      left: -117px;
    }

    .cloud-2 {
      width: 589px;
      height: 336px;
      top: 550px;
      left: 800px;
    }

    .cloud-3 {
      width: 545px;
      height: 311px;
      top: 153px;
      left: 746px;
    }

    .cloud-4 {
      width: 882px;
      height: 503px;
      top: 57px;
      left: 43px;
    }
  }
}
</style>
