<template>
  <div>
    <div :class="`video-wrap ${$isMobile ? 'video-wrap-h5' : ''}`">
      <video v-if="$isMobile" class="video-h5" width="100%" preload="metadata" x-webkit-airplay="true"
        x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint" playsinline="true"
        webkit-playsinline="true" muted :src="videoSrc" />
      <video v-else class="video" height="100%" muted preload loop>
        <source :src="videoSrc" />
      </video>

      <transition name="fadeIn">
        <div class="sound-contral" @click="contralSound" v-show="!textShow">
          <span v-show="muted"></span>
        </div>
      </transition>

    </div>
  </div>
</template>
<script>

import { mapState } from "vuex";
export default {
  data() {
    return {
      video: null,
      textShow: false,
      muted: true,
      playBtnShow: true,
    };
  },
  computed: {
    ...mapState("common", ["locale"]),
    videoSrc() {
      return process?.env?.NODE_ENV === "development" ? require("../assets/movie.mp4") : "https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/movie.mp4";
    }
  },
  components: {
    // ClickButton,
  },
  methods: {
    contralSound() {
      this.muted = !this.muted;
      this.video.muted = this.muted;
      this.videPlay();
    },
    moveNext() {
      const targetDom = document.querySelector(".aboutUs");
      if (this.$isMobile) {
        document.documentElement.scrollTop = targetDom.offsetTop;
        document.body.scrollTop = targetDom.offsetTop;
        window.pageYOffset = targetDom.offsetTop;
      } else {
        targetDom.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },
    videPlay() {
      this.video.play();
    },
    playVideo() {
      this.video.muted = false;
      this.muted = false;
      this.videPlay();
    },
    videoEnd() {
      this.textShow = true;
    },
    videoCanPlay() {
      console.log("videoLoaded");
      this.$emit("videoLoaded");
    },
    isPlaying() {
      console.log("正在播放中");
      this.playBtnShow = false;
    },
    loadVideoError() {
      console.log("视频加载出错");
      this.$emit("videoLoaded");
    },
    autoWxPlayVideo() {
      const self = this;
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.invoke(
          "getNetworkType",
          {},
          function () {
            try {
              self.video.load();
            } catch (error) {
              console.log(error);
            }
          },
          false
        );
      } else {
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            window.WeixinJSBridge.invoke("getNetworkType", {}, function () {
              try {
                self.video.load();
              } catch (error) {
                console.log(error);
              }
            });
          },
          false
        );
      }
      try {
        self.video.load();
      } catch (error) {
        console.log(error);
      }
      return false;
    },
    preLoadVideo() {
      this.video.src = "https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/movie.mp4";
      // 微信环境
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1) {
        this.autoWxPlayVideo();
      } else {
        //非微信环境
        try {
          this.video.load();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  mounted() {
    const videoClassName = this.$isMobile ? ".video-h5" : ".video";
    this.video = document.querySelector(videoClassName);
    if (this.$isMobile) {
      this.preLoadVideo();
    }

    this.video.addEventListener("ended", this.videoEnd, false);
    this.video.addEventListener("canplay", this.videoCanPlay, true);
    this.video.addEventListener("play", this.isPlaying, true);
    this.video.addEventListener("error", this.loadVideoError, true);
  },
  beforeDestroy() {
    this.video.removeEventListener("ended", this.videoEnd, true);
    this.video.removeEventListener("canplay", this.videoCanPlay, true);
    this.video.removeEventListener("play", this.isPlaying, true);
    this.video.removeEventListener("error", this.loadVideoError, true);
    this.video = null;
  },
};
</script>
<style lang="less" scoped>
.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.8s ease-in;
}

@keyframes float {
  0% {
    transform: translateY(10px);
  }

  25% {
    transform: translateY(15px);
  }

  50% {
    transform: translateY(5px);
  }

  75% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@media screen and (max-width: 630px) {
  .sound-contral {
    left: 26px !important;
    top: 100px !important;
  }
}

.video-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  // max-width: 1440px;
  margin: 0 auto;
}

// div.video-wrap-h5 {
//   .last-frame {
//     &.zh-frame {
//       background: url("../assets/video_frame_zh.png") no-repeat center;
//       background-size: 100% auto;
//     }
//     &.en-frame {
//       background: url("../assets/video_frame_en.png") no-repeat center;
//       background-size: 100% auto;
//     }
//   }
// }
.video {
  object-fit: cover;
  width: 100vw;
}

.video-h5 {
  width: 100%;
  object-fit: cover;
  width: 100vw;
  height: 100%;
}

// .last-frame {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   animation: float 8s ease infinite;
//   &.zh-frame {
//     background: url("../assets/video_frame_zh.png") no-repeat top center;
//     background-size: cover;
//   }
//   &.en-frame {
//     background: url("../assets/video_frame_en.png") no-repeat top center;
//     background-size: cover;
//   }
// }
.start-btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.play-btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 70px;
  opacity: 0.8;
}

.start-text {
  position: absolute;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  margin: 0;
  padding: 0;
  bottom: 1.28%;
  width: 100%;
  left: 0;

  pre {
    font-family: "sen-extraBold", "opposans-b";
  }
}

.sound-contral {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 80px;
  top: 120px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABA1JREFUaEPtmFvIpVMYx3//GyJcyOHCiJDkmJpMjpHMGITSaJoohxozDoVixAVTopFcmBqMcOFCDkXE5NAI00xhpClDORTNOJWkUbj669k979f6vnnf/e7TN9/+aj+123ut91lr/f/P/1nrfdYW89w0z/EzITDXCk4UmCgwZAQmKTRkAIcePusK2L4TuCqRPiLp/aFRFxPMKgHb9wLrivV2SDpjXhCwvRrYMBOspJEGbaSTVWBtXwe8WBfpOgK2lwArgDWSfu1HoZETsH018HoBYhOwtGo3EHgFWAbsAu6XVEu+NiD9sG3ztX0x8CZwYPpulXSubTcRsH02sHXG3KHEY23rxfORKWD7LOAt4IhceAdwoaQ/uxEIX9t3Aw8Ahxagb5G0sY1EKwHbxwI3AP/m57/id9UX388Cx+eCPwCLJX2fAGsVsL1UUqRYx2x/ApxXgF4u6eVuJLoSsP1Cgm8LRPn8d+BySZ8XwJoIbAO2SQoFKhJB+rhCxUWSIkC11kjA9gnAt/0gB/4BrpT0QTmuLoVsXwpU0X9V0rWpwkJgC7B/znGPpMcHIfAQ8GAOXAvsl5PGxOXvaFef9ZLemLlYA4EPY48UvkskvZckHs49Ec0fgVDhtzoS3RSYRkBStAeypk1suySxXVJEP/bCicCXwAG54E2SIp33srkmcBTwDnB6Ips6eWw/BazK/g2Sbhs7AhntJ4C7EtwWSedn/0rgmez/TFIc0+OlQAK9It8f0fxJ0jHZH/sjUqxjTTXUnKZQBa5hkx8OxJE83gRsLwbeTZy7JS1IBY4Epgq7sVXAdnlkfippURIoie2RdMi47oGIfoANW1sd17bvAJ7M/s2SolAcr01sO25rcWsL+xlYKOmXVOAlYHk+WyfpvqEI1A2u6XtUUhR706xhk5YvyvBfLenpBH808E1Rli+T9Fq/BA4C9vQIvHJ7G4i35tTpkYD2Kuby7lDVTF9JOrWaxPbtwPpsd+4UTTjaqtHngRv7JPExcLOk7wpATdXoc1F5Srqo8I0yIirZg7NvhaRIp1rr5T5wMtCpFLtYACxrpS+AlZK2NymQ/ZHzU2V39n0NnJRrbZJ0WbeFWwn0Gv0ZssewKMVXSdrcdiNL4IcBkVLV3y5xP44Kdec+IZAg4tSItKjuxHGi3Fpe8hsu9WuyfK7SJqY7pQ18OI1MgSKHL0kScZKE/Q3EgdCxBgJxwlxTRPocSXFba7WRE0glzkwS8T3NGghcAHwE/BEpJGl3K/IqIL069utnOxSIdApFKtslqVJm2pS2N0qKErovmxUFKgS2Yy/Ey+n67Ov5/55eWcwqgYLIaVnr/9UrsF799gmBXsEM4jchMEjURjlmosAooznIXBMFBonaKMfMewX+ByJd0UDjLRyeAAAAAElFTkSuQmCC) no-repeat top center;
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 1001;

  span {
    position: absolute;
    width: 22px;
    height: 2px;
    background: #ffffff;
    top: 11px;
    left: 1px;
    transform: rotate(50deg);
  }
}
</style>