<template>
  <div class="block-list">
    <CosmosWorks color="black" style="margin-bottom: 100px;margin-left: 20px;"></CosmosWorks>
    <div class="list-wrap">
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/1.png" width="100%" style="display: block;" alt="关于宇宙E-bike">
        <div class="list-desc">
          <h6 class="list-desc-h">关于宇宙E-bike</h6>
          <p class="list-desc-p">和我们一起，踏上这段激动人心的旅途</p>
        </div>
      </div>
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/2.png" width="100%" style="display: block;" alt="宇宙E-bike星球工厂">
        <div class="list-desc">
          <h6 class="list-desc-h">星球工厂</h6>
          <p class="list-desc-p">30年来，只为顶级品牌提供制造服务，产品遍布全球</p>
        </div>
      </div>
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/3.png" width="100%" style="display: block;" alt="宇宙E-bike骑行生活">
        <div class="list-desc">
          <h6 class="list-desc-h">骑行生活</h6>
          <p class="list-desc-p">我们希望通过骑行，帮助更多人发现生命的可能</p>
        </div>
      </div>
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/4.png" width="100%" style="display: block;" alt="宇宙E-bike环境与可持续发展">
        <div class="list-desc">
          <h6 class="list-desc-h">环境与可持续发展</h6>
          <p class="list-desc-p">重新定义人与自然，人与世界的关系</p>
        </div>
      </div>
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/5.png" width="100%" style="display: block;" alt="宇宙E-bike常见问题">
        <div class="list-desc">
          <h6 class="list-desc-h">常见问题</h6>
          <p  class="list-desc-p">我们将持续更新用户可能会遇到的各种问题</p>
        </div>
      </div>
      <div class="list-item">
        <img src="https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/6.png" style="display: block;" alt="宇宙E-bike维保政策">
        <div class="list-desc">
          <h6 class="list-desc-h">维保政策</h6>
          <p class="list-desc-p">从工厂到门店，提供全行业最直接透明的质保服务</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CosmosWorks from './Common/CosmosWorks.vue';
export default {
  components: {
    CosmosWorks
  }
}
</script>
<style>
.block-list {
  padding: 0 40px;

  .list-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .list-item {
      flex: 1;
      margin: 0 20px;
      width: calc((100% - 120px) / 3);
      min-width: calc((100% - 120px) / 3);
      max-width: calc((100% - 120px) / 3);
      margin-bottom: 100px;
      cursor: pointer;


    }
  }

}

.list-desc {
  border: 1px solid #CFCFCF;
  height: 80px;
  border-top: none;
  text-align: left;
  padding: 34px 0  42px 30px;

  .list-desc-h{
    margin: 0;
    padding: 0;
    font-size: 34px;
    color: #333333;
    line-height: 48px;
    margin-bottom: 32px;
  }
  .list-desc-p{
    font-size: 16px;
    color: #666666;
    line-height: 24px;
  }
}
</style>