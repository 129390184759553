<template>
  <div class="brand">
    <div class="content">
      <div class="cms-til">
        <CosmosWorks color="white"></CosmosWorks>
        <span class="divide-line"></span>
        <b>骑行品牌代理</b>
      </div>
      <p>Rapha, Standert, Pinarello, Colnago, Factot, Wilier, Lykos, Kask, Gusto, DE ROSA, Wahoo, HJC, Losse End</p>
      <Button color="#ffffff" hover="#00953B">了解详情</Button>
    </div>
  </div>
</template>
<script>
import CosmosWorks from './Common/CosmosWorks.vue';
import Button from './Common/Button.vue'
export default {
  components: {
    CosmosWorks,
    Button
  }
}
</script>
<style scoped lang="less">
.brand {
  width: 100%;
  height: 602px;
  background: url('https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/brand.png') no-repeat center;
  background-size: cover;
  position: relative;
  margin-bottom: 100px;
  .content {
    width: 85%;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);

    .cms-til {
      width: 950px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      margin-bottom: 12px;

      b {
        font-size: 60px;
      }

      .divide-line {
        display: block;
        width: 4px;
        height: 54px;
        background: #ffffff;
      }
    }

    p {
      font-size: 18px;
      line-height: 34px;
      text-align: left;
      margin-bottom: 42px;
    }
  }
}
</style>