<template>
  <div class="exprience">
    <div class="content">
      <div class="cms-til">
        <CosmosWorks color="white"></CosmosWorks>
        <span class="divide-line"></span>
        <b>体验中心</b>
      </div>
      <p>装备选购、车辆试骑、租赁、维修保养、Fitting、咖啡、骑行活动</p>
      <Button color="#ffffff" hover="#00953B" style="float: right;">查询门店</Button>
    </div>
  </div>
</template>
<script>
import CosmosWorks from './Common/CosmosWorks.vue';
import Button from './Common/Button.vue'
export default {
  components: {
    CosmosWorks,
    Button
  }
}
</script>
<style scoped lang="less">
.exprience {
  width: 100%;
  height: 602px;
  background: url('https://cosmos-static-assets.oss-cn-hangzhou.aliyuncs.com/exp.png') no-repeat center;
  background-size: cover;
  margin-bottom: 48px;
  position: relative;

  .content {
    width: 85%;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);

    .cms-til {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      margin-bottom: 12px;

      b {
        font-size: 60px;
      }

      .divide-line {
        display: block;
        width: 4px;
        height: 54px;
        background: #ffffff;
      }
    }

    p {
      font-size: 24px;
      line-height: 34px;
      text-align: right;
      margin-bottom: 42px;
    }
  }
}
</style>